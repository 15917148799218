import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS

export const getToaster = (type, message) => {
  const validTypes = ["success", "error", "warn"];
  if (!validTypes.includes(type)) {
    toast(message);
    return;
  }

  const options = {
    style: {
      backgroundColor:
        type === "success"
          ? "#6f9b2a"
          : type === "error"
            ? "#dd2a2a"
            : "#db920a",
      color: "#ffffff",
      fontSize: "12px"
    },
  };

  toast[type](message, options);
};

// Developer Component
function Developer() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any field is empty
    if (Object.values(formData).some((field) => field === "")) {
      getToaster("warn", "Please fill out all fields before submitting the form.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.streamfog.com/api/V2/api-request",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      getToaster("success", "Your request has been submitted successfully!");
      setFormData({
        name: "",
        email: "",
        company: "",
        description: "",
      });

      console.log("Success:", response.data);
    } catch (error) {
      console.error("Error:", error);
      getToaster("error", "Something went wrong while submitting the form.");
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="leave-top_margin">
      <Layout>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="dev_layout">
          <h1>
            <b className="highlighted">Try Streamfog REST API</b>
          </h1>
          <p className="api_form">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis non
            augue quis sapien sagittis aliquet.
          </p>

          <div className="row">
            {["name", "email"].map((field) => (
              <FormControl key={field} className="custom-form">
                <InputLabel className="custom-label">
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </InputLabel>
                <OutlinedInput
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  className="custom-input"
                  placeholder={
                    field === "name" ? "John Doe" : "john.doe@example.com"
                  }
                />
              </FormControl>
            ))}
          </div>

          <div className="column">
            {["company", "description"].map((field) =>
              field === "description" ? (
                <TextField
                  key={field}
                  name={field}
                  label="Description"
                  multiline
                  rows={2}
                  value={formData[field]}
                  onChange={handleChange}
                  className="custom-textfield"
                  placeholder="Briefly describe your project"
                />
              ) : (
                <FormControl key={field} className="custom-form">
                  <InputLabel className="custom-label">Company</InputLabel>
                  <OutlinedInput
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    label="Company"
                    className="custom-input"
                    placeholder="Streamfog Inc."
                  />
                </FormControl>
              )
            )}
          </div>

          <button className="launch_app" onClick={handleSubmit}>
            Submit Request
          </button>
        </div>
      </Layout>
    </div>
  );
}

export default Developer;
