import React, { useEffect } from "react";
import Layout from "../Layout.jsx";
import { Link } from "react-router-dom";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CodeIcon from "@mui/icons-material/Code";
import remoteKey from "../imgs/remote_key.png";

function ApiDoc() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="leave-top_margin">
      <Layout>
        <div className="streamer_header">
          <h1>
            <b className="highlighted">StreamFog AR Control Panel Documentation</b>
          </h1>
        </div>
        <div className="streamer_body">
          <div className="table_body">
            <div className="table_html">
              <div>
                <h1 id="intro" className="highlighted">
                  Getting Started
                </h1>
                <p>
                  The StreamFog AR Control Panel allows developers to manage and control AR experiences
                  through a REST API. This documentation covers the integration of Snap Lenses and
                  custom animations using the StreamFog External API v2.
                </p>

                <div
                  className="code-block"
                  style={{
                    backgroundColor: "darkgreen",
                  }}
                >
                  <h6 className="block-h">
                    <span role="img" aria-label="lightbulb">
                      <LightbulbOutlinedIcon />
                    </span>{" "}
                    GETTING YOUR API KEY{" "}
                    <span role="img" aria-label="key">
                      🔑
                    </span>
                  </h6>
                  <p>
                    Just fill in this{" "}
                    <Link
                      to="/developer"
                      style={{ color: "#ADD8E6", textDecoration: "underline" }}
                    >
                      online form
                    </Link>{" "}
                    to request an <strong>API Key</strong>, you should receive
                    it in your inbox in no time.
                  </p>
                </div>

                <p>
                  If you don't have a Streamfog account yet, be sure to create one here:{" "}
                  <Link to="https://app.streamfog.com" target="_blank">
                    Streamfog App
                  </Link>
                </p>

                <div
                  className="code-block"
                  style={{
                    backgroundColor: "#283642",
                  }}
                >
                  <h6 className="block-h">
                    <span role="img" aria-label="info">
                      <InfoOutlinedIcon />
                    </span>{" "}
                    Sample Implementation
                  </h6>
                  <p>
                    Check out our sample project on{" "}
                    <Link
                      to="https://github.com/streamfog/ar-control-app"
                      style={{ color: "#ADD8E6", textDecoration: "underline" }}
                      target="_blank"
                    >
                      GitHub
                    </Link>{" "}
                    to see a practical implementation of the API client in JavaScript.
                  </p>
                </div>

                <h3>Authentication</h3>
                <p>
                  All API requests require two essential components for authentication and session management:
                </p>
                <ul>
                  <li>
                    <strong>client-id</strong>: Provided in the request header
                  </li>
                  <li>
                    <strong>remoteKey</strong>: Unique identifier for the AR session. Users can find their remote key in the App camera settings page:
                  </li>
                  <img src={remoteKey} width={400} alt="Remote Key Location" />
                </ul>

                <h3>Base URL</h3>
                <ul>
                  <li>https://api.streamfog.com/api/v2/external-api</li>
                </ul>

                <div
                  className="code-block"
                  style={{
                    backgroundColor: "#1e293b",
                  }}
                >
                  <h6 className="block-h">
                    <span role="img" aria-label="code">
                      <CodeIcon />
                    </span>{" "}
                    API Endpoints
                  </h6>

                  <h4>Get Inventory</h4>
                  <pre>
                    {`GET /inventory/{"{remoteKey}"}
client-id: "{clientId}"`}
                  </pre>
                  <p>Returns:</p>
                  <pre>
                    {`{
  data: {
    snapLenses: Array<{
      id: string
      name: string
      iconUrl: string
    }>
    animations: Array<{
      id: string
      name: string
      thumbnail: string
    }>
  }
}`}
                  </pre>

                  <h4>Activate Lens</h4>
                  <pre>
                    {`POST /activateLens
client-id: {clientId}
Content-Type: application/json

{
  "remoteKey": string,
  "lensId": string
}`}
                  </pre>


                  <h4>Disable Lens</h4>
                  <pre>
                    {`POST /disableLens
client-id: {clientId}
Content-Type: application/json

{
  "remoteKey": string
}`}
                  </pre>

                  <h4>Play Video Animation</h4>
                  <pre>
                    {`POST /playVideoAnim
client-id: {clientId}
Content-Type: application/json

{
  "remoteKey": string,
  "animId": string
}`}
                  </pre>
                </div>




                <div
                  className="code-block"
                  style={{
                    backgroundColor: "#283642",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    For further assistance, please contact our support team at
                    "kev@streamfog.com"
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default ApiDoc;