import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "./imgs/logo_white.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import learn from "./imgs/fluent-emoji_brain.png";
import company from "./imgs/fluent-emoji_office-building.png";
import collaboration from "./imgs/fluent-emoji_handshake.png";
import community from "./imgs/group-svgrepo-com.svg";
import discord from "./imgs/discord_logo.svg";
import twitter from "./imgs/x_logo.svg";
import youtube from "./imgs/youtube_logo.svg";
import linkedIn from "./imgs/linkedin_logo.svg";
import userGuide from "./imgs/user_guide_icon.png";



const subMenuItems = [
  {
    imgSrc: learn,
    altText: 'Learn',
    heading: 'LEARN',
    links: [
      { to: 'https://app.streamfog.com', text: 'Getting Started', description: 'Log in with Twitch and enjoy' }
    ]
  },
  {
    imgSrc: userGuide,
    altText: 'User Guide',
    heading: 'USER GUIDE',
    links: [
      { to: '/streamers', text: 'Tutorial', description: 'To use our application' },
      { to: '/artists', text: 'Artist Guide', description: 'To make 3D alerts with us' }
    ]
  },
  {
    imgSrc: company,
    altText: 'Company',
    heading: 'COMPANY',
    links: [
      { to: '/about', text: 'About Us', description: 'Learn about our company' },
      { to: 'mailto:kev@streamfog.com', text: 'Contact Us', description: 'Feel free to get in touch with us.' }
    ]
  },

  {
    imgSrc: collaboration,
    altText: 'Collaboration',
    heading: 'COLLABORATION',
    links: [
      { to: '/agencies', text: 'Advertising', description: 'Influencer Marketing in 3D' },
      { to: '/credits', text: 'Credits', description: 'Our gratitude for those who help us to build Streamfog' }
    ]
  }
];



const SubMenuItem = ({ imgSrc, altText, heading, links }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);

  };

  return (
    <div>
      <h2 className="sub-menu-h" onClick={toggleOpen} style={{ cursor: 'pointer' }}>
        <img src={imgSrc} alt={altText} />
        {heading}
        {isOpen ? <ArrowDropUpIcon style={{ marginLeft: '10px' }} /> : <ArrowDropDownIcon style={{ marginLeft: '10px' }} />}
      </h2>
      {isOpen && (
        <div className="submenu-links">
          {links.map((link, index) => (
            <Link key={index} to={link.to}>
              {link.text} <p style={{ fontSize: "12px" }}>{link.description}</p>

            </Link>
          ))}
        </div>
      )}


    </div>
  );
};

function Header() {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [showResources, setShowResources] = useState(false);
  const headerRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const desktopMenuRef = useRef(null);
  let location = useLocation();
  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY > 100) {
        headerRef.current.classList.add("scrolled");
      } else {
        headerRef.current.classList.remove("scrolled");
      }
    };
    const handleClickOutside = (e) => {
      if (
        headerRef.current.contains(e.target) === false &&
        (isMobile
          ? mobileMenuRef.current && mobileMenuRef.current.contains(e.target) === false
          : desktopMenuRef.current && desktopMenuRef.current.contains(e.target) === false)
      ) {
        setShowResources(false);
      }
    };
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isMobile]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOpenResources = () => {
    setShowResources(!showResources);
  };

  const isLandingPage = location.pathname === "/";

  return (
    <>
      <div className="site_header" ref={headerRef}>
        <div className="container-fluid">
          <div className="header_wrapper">
            <Link className="site_logo" to={"/"}>
              <img src={Logo} alt="Logo" />
            </Link>
            <div>
              <div className={`resource_link`} onClick={handleOpenResources}>
                RESOURCES
                {showResources ? (
                  <span>
                    <ArrowDropUpIcon />
                  </span>
                ) : (
                  <span>
                    <ArrowDropDownIcon />
                  </span>
                )}
              </div>
              <Link
                to={"https://app.streamfog.com/"}
                target="_blank"
                rel="noopener noreferrer"
                className="ms-5"
                style={{ visibility: isLandingPage ? "hidden" : "visible" }}
              >
                <span className="launch_app">LAUNCH APP</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {isMobile ? (
        <MobileDesign
          showResources={showResources}
          subMenuItems={subMenuItems}
          mobileMenuRef={mobileMenuRef}
        />
      ) : (
        <DesktopDesign showResources={showResources} desktopMenuRef={desktopMenuRef} />
      )}

    </>
  );
}

export default Header;


const MobileDesign = ({ showResources, subMenuItems, mobileMenuRef }) => (
  <div ref={mobileMenuRef}>
    {showResources && (
      <div className="resources">
        <div className="sub-menu">
          {subMenuItems.map((item, index) => (
            <SubMenuItem key={index} {...item} />
          ))}
        </div>
        <div className="community_menu" style={{ marginRight: "7%" }}>
          <h2 className="sub-menu-h">
            {" "}
            <img src={community} alt="community" /> SOCIALS
          </h2>

          <Link to="https://discord.gg/Z68pjTHfuK" target="_blank">
            <img src={discord} alt="discord" width={30} height={30} />
          </Link>
          <Link
            to={"https://twitter.com/streamfog"}
            style={{
              marginLeft: "20px",
            }}
          >
            <img src={twitter} alt="twitter" width={30} height={25} />
          </Link>
          <Link
            to={"https://www.youtube.com/@streamfog"} target="_blank"
            style={{
              marginLeft: "20px",
            }}
          >
            <img src={youtube} alt="youtube" width={30} height={30} />
          </Link>
          <Link
            to={"https://www.linkedin.com/company/streamfog/"} target="_blank"
            style={{
              marginLeft: "20px",
            }}
          >
            <img src={linkedIn} alt="linkedIn" width={30} height={30} />
          </Link>
        </div>
      </div>
    )}
  </div>
);

const DesktopDesign = ({ showResources, desktopMenuRef }) => (
  <div ref={desktopMenuRef}>
    {showResources && (
      <div className="resources">
        <div className="sub-menu">
          <h2 className="sub-menu-h">
            {" "}
            <img src={learn} alt="Learn" />
            LEARN
          </h2>
          <Link
            to={"https://app.streamfog.com"}
            target="_blank"
            rel="noopener noreferrer"
            className="image-link"
          >
            Getting Started <p> Log in with Twitch and enjoy </p>
          </Link>


          <h2 className="sub-menu-h">
            {" "}
            <img src={userGuide} alt="userguide" />
            USER GUIDE
          </h2>
          <Link to={"/streamers"}>
            Tutorial <p> To use our application</p>
          </Link>
          <Link to="/artists">
            Artist Guide{" "}
            <p>
              To make 3D alerts with us
            </p>
          </Link>
        </div>
        <div className="vertical-line"></div>
        <div className="sub-menu">
          <h2 className="sub-menu-h">
            {" "}
            <img src={company} alt="company" /> COMPANY
          </h2>
          <Link to="/about">
            About Us <p>Learn about our company </p>
          </Link>

          <Link to={"mailto:kev@streamfog.com"}>
            Contact Us <p>Feel free to get in touch with us.</p>
          </Link>

          <div className="community_menu" style={{ marginTop: "30px" }}>
            <h2 className="sub-menu-h">
              {" "}
              <img src={community} alt="community" /> SOCIALS
            </h2>

            <Link to="https://discord.gg/Z68pjTHfuK" target="_blank">
              <img src={discord} alt="discord" width={30} height={30} />
            </Link>
            <Link
              to={"https://twitter.com/streamfog"}
              style={{
                marginLeft: "20px",
              }}
            >
              <img src={twitter} alt="twitter" width={30} height={25} />
            </Link>
            <Link
              to={"https://www.youtube.com/@streamfog"} target="_blank"
              style={{
                marginLeft: "20px",
              }}
            >
              <img src={youtube} alt="youtube" width={30} height={30} />
            </Link>
            <Link
              to={"https://www.linkedin.com/company/streamfog/"} target="_blank"
              style={{
                marginLeft: "20px",
              }}
            >
              <img src={linkedIn} alt="linkedIn" width={30} height={30} />
            </Link>
          </div>
        </div>
        <div className="vertical-line"></div>
        <div className="sub-menu">
          <h2 className="sub-menu-h">
            {" "}
            <img src={collaboration} alt="collaboration" /> COLLABORATION
          </h2>

          <Link to="/agencies">
            Advertising <p>Influencer Marketing in 3D</p>
          </Link>
          <Link to="/credits">
            Credits{" "}
            <p>
              Our gratitude for those who help us to build <b>Streamfog</b>
            </p>
          </Link>
          <Link to="/api-documentation">
            Developer{" "}
            <p>
              Trigger AR effects from your own app
            </p>
          </Link>
        </div>
      </div>
    )}
  </div>
);

